<template>
  <v-card>
    <v-toolbar>
      <v-row>
        <v-col>
          <v-select v-model="filter" :items="majorFilterOptions" label="Major/Minor Filter" outlined hide-details dense class="pt-2 pb-2"></v-select>
        </v-col>
        <v-col>
          <div :style="'background-color:' + (filterEventReverse ? 'black' : 'beige')" class="pa-2 ml-4">
            <v-select v-model="filterEvent" :items="eventList" :dark="filterEventReverse" :label="filterEventReverse ? 'Did Not Scan for Event' : 'Scanned for Event'" item-text="name" item-value="_id" outlined hide-details dense clearable>
              <template v-slot:prepend>
                <v-btn icon small @click="filterEventReverse = !filterEventReverse">
                  <v-icon small>fas fa-toggle-{{ filterEventReverse ? 'on' : 'off' }}</v-icon>
                </v-btn>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ stringFormatDate(item.date) }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ 'students' in item ? item.students.length : 0 }} Attended</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </div>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table :items="students" :headers="headers">
      <template v-slot:no-data>
        <v-alert v-if="isLoading" type="info" outlined>Loading data...</v-alert>
      </template>
      <template v-slot:item.major="{ item }">{{ item.major.join('; ') }}</template>
      <template v-slot:item.minor="{ item }">{{ item.minor.join('; ') }}</template>
      <template v-slot:item.advisor="{ item }">{{ item.advisor }}</template>
      <template v-slot:item.courses="{ item }">
        <v-chip v-for="{ title, crn } in item.courses" :key="crn" label outlined small>{{ title }}</v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
import { saveAs } from 'file-saver'

export default {
  props: {
    term: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const isLoading = ref(true)
    const students = ref([])
    const headers = ref([
      { text: 'Banner ID', value: 'bannerId' },
      { text: 'Name', value: 'name' },
      { text: 'Class Level', value: 'classLevel' },
      { text: 'Major', value: 'major' },
      { text: 'Minor', value: 'minor' },
      { text: 'Advisor', value: 'advisor' },
      { text: 'Events Attended', value: 'events' },
      { text: 'MUS111/MUS171', value: 'courses', sortable: false }
    ])

    const filter = ref('')
    const filterEvent = ref('')
    const filterEventReverse = ref(false)
    const majorFilterOptions = ref([
      { text: 'Music Majors', value: 'major' },
      { text: 'Music Minors', value: 'minor' },
      { text: 'Non-Major/Minor', value: 'other' },
      { text: 'MUS111 Students', value: 'MUS111' },
      { text: 'MUS171 Students', value: 'MUS171' },
      { text: 'All Music Students', value: '' }
    ])

    async function loadStudents () {
      isLoading.value = true
      students.value = []
      const $match = { term: props.term }
      if (filter.value === 'major') {
        $match['academics.major'] = 'MUS'
      } else if (filter.value === 'minor') {
        $match['academics.minor'] = 'MUS'
      } else if (filter.value === 'other') {
        $match['schedule.title'] = { $regex: 'MUS[1-4]0[1-8]' }
        $match['academics.major'] = { $ne: 'MUS' }
        $match['academics.minor'] = { $ne: 'MUS' }
      } else if (filter.value === 'MUS111') {
        $match['schedule.title'] = { $regex: 'MUS111' }
      } else if (filter.value === 'MUS171') {
        $match['schedule.title'] = { $regex: 'MUS171' }
      } else {
        $match.$or = [
          { 'academics.major': 'MUS' },
          { 'academics.minor': 'MUS' },
          { 'schedule.title': { $regex: 'MUS[1-4]0[1-8]' } }
        ]
      }
      const aggregate = [
        { $match },
        { $project: {
          bannerId: 1,
          pidm: 1,
          name: 1,
          classLevel: '$academics.classLevel',
          major: '$academics.major',
          minor: '$academics.minor',
          advisor: '$academics.advisor',
          classes: '$schedule'
        } },
        { $lookup: {
          from: 'Student-MusicScanEvent',
          localField: 'bannerId',
          foreignField: 'students',
          as: 'events',
          pipeline: [ { $match: { term: props.term } } ]
        } },
        { $project: {
          bannerId: 1,
          pidm: 1,
          name: 1,
          classLevel: 1,
          major: 1,
          minor: 1,
          advisor: 1,
          classes: 1,
          events: {
            $cond: {
              if: {
                $isArray: '$events'
              },
              then: {
                $size: '$events'
              },
              else: 0
            }
          }
        } }
      ]
      if (filterEvent.value != null && filterEvent.value !== '') {
        aggregate.push({
          $lookup: {
            from: 'Student-MusicScanEvent',
            localField: 'bannerId',
            foreignField: 'students',
            as: 'event',
            pipeline: [{ $match: { _id: filterEvent.value } }]
          }
        })
        aggregate.push({ $match: { 'event.0': { $exists: !filterEventReverse.value } } })
      }
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      const regex = /MUS1[17]1/
      students.value = data.map((row) => {
        let temp = row.advisor.filter(({ primary, code }) => primary && code === 'MAJR')
        const courses = row.classes.filter(({ title }) => regex.test(title))
        if (temp.length > 0) {
          return { ...row, advisor: temp[0].name || temp[0], courses }
        }
        temp = row.advisor.filter(({ code }) => code === 'MAJR')
        if (temp.length > 0) {
          return { ...row, advisor: temp[0].name, courses }
        } else if ('advisor' in row && Array.isArray(row.advisor) && row.advisor.length > 0) {
          return { ...row, advisor: row.advisor[0].name, courses }
        }
        return { ...row, advisor: '**NO ADVISOR FOUND**', courses }
      })
      isLoading.value = false
    }
    const eventList = ref([])
    async function loadEvents () {
      const aggregate = [
        { $match: { term: props.term } },
        { $project: {
          _id: 1,
          date: 1,
          name: 1,
          students: { $cond: { if: { $isArray: '$students' }, then: { $size: '$students' }, else: '0' } }
        } },
        { $sort: { date: -1 } }
      ]
      const { data } = await root.$feathers.service('student/music/scan-event').find({ query: { aggregate } })
      eventList.value = data
    }

    onMounted(async () => {
      loadStudents()
    })
    watch(() => props.term, () => {
      loadStudents()
      loadEvents()
    })
    watch([filter, filterEvent, filterEventReverse], () => loadStudents())

    async function exportList () {
      const arr = [ ...headers.value ]
      arr.splice(4, 0, { text: 'Sports', value: 'sports', width: 20 })
      arr.splice(2, 0, { text: 'Email Address', value: 'email', width: 30 })
      const header = arr.filter((text) => text !== '').map(({ text: header, value: key, exportWidth: width }) => { return { header, align: (header === 'Events Attended' ? 'right' : 'left'), key, width } })
      let filename = 'Chapel Student Export.xlsx'
      const rows = students.value.map((row) => {
        const major = row.major ? row.major.join('; ') : ''
        const minor = row.minor ? row.minor.join('; ') : ''
        const courses = row.courses ? row.courses.join('; ') : ''
        return { ...row, courses, major, minor }
      })
      const data = await root.$feathers.service('export/xlsx').create({ filename, header, rows })
      saveAs(new Blob([data.buffer]), filename)
    }

    return {
      isLoading,
      students,
      headers,
      filter,
      filterEvent,
      filterEventReverse,
      majorFilterOptions,
      eventList,
      exportList,
      stringFormatDate
    }
  }
}
</script>
