var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-row',[_c('v-col',[_c('v-select',{staticClass:"pt-2 pb-2",attrs:{"items":_vm.majorFilterOptions,"label":"Major/Minor Filter","outlined":"","hide-details":"","dense":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',[_c('div',{staticClass:"pa-2 ml-4",style:('background-color:' + (_vm.filterEventReverse ? 'black' : 'beige'))},[_c('v-select',{attrs:{"items":_vm.eventList,"dark":_vm.filterEventReverse,"label":_vm.filterEventReverse ? 'Did Not Scan for Event' : 'Scanned for Event',"item-text":"name","item-value":"_id","outlined":"","hide-details":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.filterEventReverse = !_vm.filterEventReverse}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-toggle-"+_vm._s(_vm.filterEventReverse ? 'on' : 'off'))])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.stringFormatDate(item.date)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s('students' in item ? item.students.length : 0)+" Attended")])],1)]}}]),model:{value:(_vm.filterEvent),callback:function ($$v) {_vm.filterEvent=$$v},expression:"filterEvent"}})],1)])],1)],1),_c('v-data-table',{attrs:{"items":_vm.students,"headers":_vm.headers},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.isLoading)?_c('v-alert',{attrs:{"type":"info","outlined":""}},[_vm._v("Loading data...")]):_vm._e()]},proxy:true},{key:"item.major",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.major.join('; ')))]}},{key:"item.minor",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.minor.join('; ')))]}},{key:"item.advisor",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.advisor))]}},{key:"item.courses",fn:function(ref){
var item = ref.item;
return _vm._l((item.courses),function(ref){
var title = ref.title;
var crn = ref.crn;
return _c('v-chip',{key:crn,attrs:{"label":"","outlined":"","small":""}},[_vm._v(_vm._s(title))])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }